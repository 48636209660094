html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayBlackItalic.101d7f8c.woff2") format("woff2"), url("WulkanDisplayBlackItalic.8910fdfe.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayBlack.fa5c43cd.woff2") format("woff2"), url("WulkanDisplayBlack.c13c0802.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayRegular.4e987af9.woff2") format("woff2"), url("WulkanDisplayRegular.f173dd7e.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayBold.bd49dcd0.woff2") format("woff2"), url("WulkanDisplayBold.3cc6233c.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayBoldItalic.211694e8.woff2") format("woff2"), url("WulkanDisplayBoldItalic.56ea0a45.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayItalic.38767a68.woff2") format("woff2"), url("WulkanDisplayItalic.3a4cd9ac.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayLight.a047abf2.woff2") format("woff2"), url("WulkanDisplayLight.2e7e0469.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayLightItalic.e6899da5.woff2") format("woff2"), url("WulkanDisplayLightItalic.0e5eaef3.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayMedium.98f2dbd4.woff2") format("woff2"), url("WulkanDisplayMedium.19a48710.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplayMediumItalic.953b91b4.woff2") format("woff2"), url("WulkanDisplayMediumItalic.7c9efcbd.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplaySemiBoldItalic.7c4232ed.woff2") format("woff2"), url("WulkanDisplaySemiBoldItalic.0a4bc3ad.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Wulkan Display;
  src: url("WulkanDisplaySemiBold.ba0cbc4c.woff2") format("woff2"), url("WulkanDisplaySemiBold.39bb2164.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html, body {
  background: #0f121a;
}

.abigail {
  color: #bdbdbd;
  fill: #bdbdbd;
  background: #0f121a;
  font-family: Wulkan Display, Georgia, serif;
}

.abigail main {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 40px;
}

@media (width <= 670px) {
  .abigail main {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (width <= 350px) {
  .abigail main {
    padding: 40px;
  }
}

.abigail main * + * {
  margin-top: 1em;
}

.abigail .hero {
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
  font-size: 46px;
  font-weight: 600;
}

.abigail .hero blockquote {
  color: #55cbf2;
}

.abigail .hero blockquote cite {
  display: block;
}

.abigail .hero .logo {
  fill: #6fcf97;
  display: inline-block;
}

.abigail .hero .logo svg {
  max-width: 100%;
  height: auto;
}

@media (width <= 670px) {
  .abigail .hero {
    font-size: 36px;
  }
}

@media (width <= 350px) {
  .abigail .hero {
    font-size: 24px;
  }
}

.abigail .where {
  border-collapse: collapse;
  width: 100%;
  font-size: 46px;
  font-style: italic;
  font-weight: 600;
  display: table;
}

@media (width <= 670px) {
  .abigail .where {
    font-size: 36px;
  }
}

@media (width <= 350px) {
  .abigail .where {
    font-size: 24px;
  }
}

.abigail .where > section {
  border-bottom: 16px solid #0000;
  display: table-row;
}

.abigail .where > section > * {
  vertical-align: middle;
  display: table-cell;
}

.abigail .where header, .abigail .where .content {
  word-wrap: break-word;
  white-space: nowrap;
}

.abigail .where header {
  text-align: right;
}

.abigail .where .content {
  text-align: left;
}

.abigail .where .separator {
  user-select: none;
  pointer-events: none;
  vertical-align: text-bottom;
  width: 100%;
  padding: 0 16px;
  position: relative;
  overflow: hidden;
}

.abigail .where .separator .dots {
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  position: absolute;
  inset: 0 16px;
  overflow: hidden;
}

@media (width <= 350px) {
  .abigail .where .separator {
    opacity: 0;
  }
}

.abigail .where .online {
  color: #f2c94c;
}

.abigail button.play-stop {
  all: unset;
  color: #f2c94c;
  cursor: pointer;
  border: 5px solid #f2c94c;
  border-radius: 999px;
  padding: 10px 20px;
  font-family: Helvetica Neue, Helvetica, system-ui;
  font-size: 36px;
  font-style: normal;
}

@media (width <= 670px) {
  .abigail button.play-stop {
    font-size: 24px;
  }

  .abigail button.play-stop .icon svg {
    vertical-align: bottom;
  }
}

.abigail button.play-stop .icon {
  fill: #f2c94c;
  display: inline;
}

.abigail button.play-stop:hover {
  color: #0f121a;
  background-color: #f2c94c;
}

.abigail button.play-stop:hover .icon svg {
  fill: #0f121a;
}

.abigail button.play-stop:active {
  color: #0f121a80;
}

.abigail button.play-stop:active .icon svg {
  fill: #0f121a80;
}

.abigail footer {
  width: 100%;
  margin-top: 4em;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5;
  display: table;
}

.abigail footer a {
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

.abigail footer a:hover {
  text-decoration: underline;
}

.abigail footer .about {
  margin-right: 2em;
}

.abigail footer .contact {
  color: #f178b6;
  padding-right: 15%;
  font-style: normal;
  display: table-cell;
}

.abigail footer .contact li {
  white-space: nowrap;
}

.abigail footer .contact * + * {
  margin-top: 0;
}

@media (width <= 670px) {
  .abigail footer .contact {
    padding-right: 0;
    display: block;
  }

  .abigail footer .about {
    margin-right: 0;
  }
}

.abigail .featured {
  border: 2px solid #555;
  margin: 4em 0 2em;
}

.abigail .featured header {
  color: #777;
  border-bottom: 2px solid #555;
  margin-bottom: 1em;
  padding: 1em 0;
}

.abigail .featured header h1 {
  text-transform: uppercase;
  letter-spacing: 1em;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

@media (width <= 350px) {
  .abigail .featured header h1 {
    font-size: 16px;
  }
}

.abigail .featured section {
  margin-bottom: 2em;
}

.abigail .featurette {
  border-radius: 4px;
  max-width: 300px;
  margin: 0 auto;
  padding: 32px;
  display: block;
}

.abigail .featurette h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 600;
}

.abigail .featurette h1 .small {
  font-size: 32px;
}

@media (width <= 350px) {
  .abigail .featurette h1 {
    font-size: 32px;
  }
}

.abigail .featurette h2 {
  color: #777;
}

.abigail .featurette nav {
  margin-top: 2em;
  font-weight: 600;
}

.abigail .featurette nav > * {
  border-radius: 999px;
  padding: 8px 16px;
}

.abigail .featurette nav span {
  color: #999;
  border: 2px solid #999;
}

.abigail .featurette nav a {
  color: #000;
  background: #999;
  text-decoration: none;
}

.abigail .featurette nav a:hover {
  background: #fff;
}

.abigail .subpage h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
}

.abigail .archive article h1 {
  color: #999;
  font-size: 24px;
  font-weight: 600;
}

.abigail .archive article a {
  color: inherit;
  margin-bottom: 1em;
}

.abigail .message {
  font-size: 18px;
  line-height: 1.4;
}

.abigail .message blockquote {
  padding: 1em;
  font-weight: 600;
}

.abigail .message em {
  font-style: italic;
}

.abigail .message a {
  color: inherit;
}
/*# sourceMappingURL=404.3edd41f8.css.map */
