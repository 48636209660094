@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayBlackItalic.woff2') format('woff2'),
        url('WulkanDisplayBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayBlack.woff2') format('woff2'),
        url('WulkanDisplayBlack.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayRegular.woff2') format('woff2'),
        url('WulkanDisplayRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayBold.woff2') format('woff2'),
        url('WulkanDisplayBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayBoldItalic.woff2') format('woff2'),
        url('WulkanDisplayBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayItalic.woff2') format('woff2'),
        url('WulkanDisplayItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayLight.woff2') format('woff2'),
        url('WulkanDisplayLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayLightItalic.woff2') format('woff2'),
        url('WulkanDisplayLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayMedium.woff2') format('woff2'),
        url('WulkanDisplayMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplayMediumItalic.woff2') format('woff2'),
        url('WulkanDisplayMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplaySemiBoldItalic.woff2') format('woff2'),
        url('WulkanDisplaySemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('WulkanDisplaySemiBold.woff2') format('woff2'),
        url('WulkanDisplaySemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

