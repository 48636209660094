@import "reset.css";
@import "fonts/stylesheet.css";

$system-teal: #55cbf2;
$system-green: #6FCF97;
$system-yellow: #F2C94C;
$system-pink: #F178B6;
$system-primary-label: #BDBDBD;
$system-background: #0f121a;

$font-size-extra-large: 46px;
$font-size-large: 36px;
$font-size-body: 24px;
$font-size-caption: 18px;


$maximum-layout-width-large: 670px;
$maximum-layout-width-medium: 350px;

@mixin at-maximum-width($width) {
  @media (media and max-width: $width) {
    @content;
  }
}

html, body {
  background: $system-background;
}

.abigail {
  font-family: "Wulkan Display", Georgia, serif;
  
  background: $system-background;
  color: $system-primary-label;
  fill: $system-primary-label;
  
  main {
    max-width: 800px;
    margin: 0 auto;
    
    padding: 100px 40px;
    
    @include at-maximum-width($maximum-layout-width-large) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    
    @include at-maximum-width($maximum-layout-width-medium) {
      padding: 40px;
    }
    
    * + * {
      margin-top: 1em;
    }
  }
  
  // Header:
  .hero {
    max-width: 480px;
    margin: 0 auto;
    
    font-size: $font-size-extra-large;
    font-weight: 600;
    text-align: center;
    
    blockquote {
      color: $system-teal;
      
      cite {
        display: block;
      }
    }
    
    .logo {
      display: inline-block;
      fill: $system-green;
      
      svg {
        max-width: 100%;
        height: auto;
      }
     
    }
    
    @include at-maximum-width($maximum-layout-width-large) {
      font-size: $font-size-large;
    }
    
    @include at-maximum-width($maximum-layout-width-medium) {
      font-size: 24px;
    }
  }
  
  // Where?
  .where {
    display: table;
    border-collapse: collapse;
    width: 100%;
    
    font-size: 46px;
    font-weight: 600;
    font-style: italic;
    
    @include at-maximum-width($maximum-layout-width-large) {
      font-size: $font-size-large;
    }
    
    @include at-maximum-width($maximum-layout-width-medium) {
      font-size: $font-size-body;
    }
    
    & > section {
      display: table-row;
      border-bottom: 16px solid transparent;
      
      & > * {
        display: table-cell;
        vertical-align: middle;
      }
      
    }
    
    header, .content {
      word-wrap: break-word;
      white-space: nowrap;
    }
    
    header {
      text-align: right;
    }
    
    .content {
      text-align: left;
    }
    
    .separator {
      width: 100%;
      user-select: none;
      pointer-events: none;
      
      padding: 0 16px;
      position: relative;
      overflow: hidden;
      
      vertical-align: text-bottom;
      
      .dots {
        position: absolute;
        top: 0;
        left: 16px;
        right: 16px;
        bottom: 0;
        
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
      
      @include at-maximum-width($maximum-layout-width-medium) {
        opacity: 0;
      }
    }
    
    .online {
      color: $system-yellow;
    }
  }
  
  // Player widget:
  button.play-stop {
    $tint-color: $system-yellow;
    $padding: 10px;
    $border-width: 5px;
    
    all: unset;
    
    font-family: "Helvetica Neue", Helvetica, system-ui;
    font-size: $font-size-large;
    font-style: normal;
    
    @include at-maximum-width($maximum-layout-width-large) {
      font-size: $font-size-body;
      
      .icon svg {
        vertical-align: bottom;
      }
    }
    
    color: $tint-color;
    
    padding: $padding ($padding * 2);
    border: $border-width solid $tint-color;
    border-radius: 999px;
    
    cursor: pointer;
        
    .icon {
      display: inline;
      fill: $tint-color;
    }
    
    &:hover {
      color: $system-background;
      background-color: $tint-color;
      
      .icon svg {
        fill: $system-background;
      }
    }
    
    &:active {
      color: rgba($system-background, 0.5);
      
      .icon svg {
        fill: rgba($system-background, 0.5);
      }
    }
  }
  
  // Footer
  footer {
    margin-top: 4em;
    
    font-size: $font-size-caption;
    font-style: italic;
    font-weight: 500;
    
    display: table;
    width: 100%;
    
    line-height: 1.5;
    
    a {
      color: inherit;
      text-decoration: none;
      font-weight: 700;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    .about {
      margin-right: 2em;
    }
    
    .contact {
      display: table-cell;
      
      padding-right: 15%;
      
      font-style: normal;
      color: $system-pink;
      
      li {
        white-space: nowrap;
      }
      
      * + * {
        margin-top: 0px;
      }
    }
    
    @include at-maximum-width($maximum-layout-width-large) {
      .contact {
        display: block;
        padding-right: 0;
      }
      
      .about {
        margin-right: 0;
      }
    }
  }
  
  // Featured
  .featured {
    margin: 2em 0;
    margin-top: 4em;
    
    border: 2px solid #555;
    
    header {
      color: #777;
      
      padding: 1em 0;
      border-bottom: 2px solid #555;
      margin-bottom: 1em;
            
      h1 {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1em;
        
        text-align: center;
        
        @include at-maximum-width($maximum-layout-width-medium) {
          font-size: 16px;
        }
      }
      
    }
    
    section {
      margin-bottom: 2em;
    }
  }
  
  .featurette {
    display: block;
    margin: 0 auto;
    max-width: 300px;
    
    padding: 32px;
    border-radius: 4px;
    
    h1 {
      font-size: 45px;
      font-weight: 600;
      
      color: white;
      
      .small {
        font-size: 32px;
      }
      
      @include at-maximum-width($maximum-layout-width-medium) {
        font-size: 32px;
      }
    }
    
    h2 {
      color: #777;
    }
    
    nav {
      margin-top: 2em;
      font-weight: 600;
      
      & > * {
        border-radius: 999px;
        padding: 8px 16px;
      }
      
      span {
        border: 2px solid #999;
        color: #999;
      }
      
      a {
        text-decoration: none;
        color: black;
        background: #999;
        
        &:hover {
          background: white;
        }
      }
    }
  }
  
  // Pages
  
  .subpage {
    h1 {
      font-size: 50px;
      font-weight: 600;
      
      color: white;
    }
  }
  
  // Archive
  
  .archive article {
    
    h1 {
      font-size: 24px;
      font-weight: 600;
      
      color: #999;
    }
    
    a {
      color: inherit;
      
      margin-bottom: 1em;
    }
  }
  
  .message {
    font-size: 18px;
    line-height: 1.4;
    
    blockquote {
      font-weight: 600;
      padding: 1em;
    }
    
    em {
      font-style: italic;
    }
    
    a {
      color: inherit;
    }
    
  }
}
